import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Consejos from "../utils/Consejos";
import SessionOut from "../components/SessionOut";
import Modal from "../utils/Modal";
import MercadoPago from "../utils/Mercadopago.js"
import {
  PersonCircle,
  Coin,
  Eye,
  EyeSlash,
  CupHotFill,
  ArrowDownUp,
  ClockHistory,
  ExclamationCircle,
  CheckCircle,
} from "react-bootstrap-icons";

function MozoDashboard({ token }) {
  const [isVisible, setIsVisible] = useState(true);
  const [weeklyData, setWeeklyData] = useState([]);
  const [groupedPayments, setGroupedPayments] = useState({});
  const [totalPayments, setTotalPayments] = useState(0);
  const [isAmountVisible, setIsAmountVisible] = useState(true);
  const [numeroOperacion, setNumeroOperacion] = useState("");
  //   const hasPayments = Object.entries(groupedPayments).some(([_, payments]) => payments.length > 0);
  const navigate = useNavigate();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [totalPendiente, setTotalPendiente] = useState(0);
  const [totalTransferido, setTotalTransferido] = useState(0);
  const [totalPropinas, setTotalPropinas] = useState(0);
  const [apiMessage, setApiMessage] = useState(""); 
  const [numeroOperacionAyuda, setNumeroOperacionAyuda] = useState(true);

  const openModal = (data) => {
    setModalData(data);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalData(null);
    setApiMessage(""); 
    window.location.reload(); 
  };

  const getAbbreviatedDay = (date) => {
    const days = ["DOM", "LUN", "MAR", "MIÉ", "JUE", "VIE", "SÁB"];
    return days[new Date(date).getDay()];
  };


  const verificarTransferencia = async () => {
    const params = new URLSearchParams({
      numero_operacion: numeroOperacion,
      id_transaccion: modalData.id_transaccion,
      cuit_destinatario: modalData.cuit_acreedor,
    });
  
    try {
      const response = await fetch('https://tippit-qr.com:8000/mp/validar-transferencia', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded', // Configurar el tipo de contenido
        },
        body: params.toString(), // Enviar los parámetros como una cadena de consulta
      });
  
      if (response.ok) {
        setApiMessage("Transferencia validada con éxito."); // Mensaje de éxito
      } else {
        setApiMessage(`Error en la validación de la transferencia`);
      }
    } catch (error) {
      setApiMessage("Error de red. Inténtalo nuevamente."); // Mensaje de error de red
    }
  };
  
  


  const formatDate = (dateString) => {
    const paymentDate = new Date(dateString);
    const currentDate = new Date();
    const paymentDay = paymentDate.getDate();
    const paymentMonth = paymentDate.getMonth();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    if (paymentDay === currentDay && paymentMonth === currentMonth) {
      return "Hoy";
    } else if (paymentDay === currentDay - 1 && paymentMonth === currentMonth) {
      return "Ayer";
    } else {
      return paymentDate.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
      });
    }
  };

  useEffect(() => {
    if (!token) {
      console.error("No se encontró el token del Mozo");
      navigate("/");
      return;
    }

    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 600);

    return () => clearTimeout(timeout);
  }, [token, navigate]);

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        const fetchWeeklyData = (await import("../components/WeeklyData"))
          .default;
        const data = await fetchWeeklyData(token, navigate);
        if (data === undefined) {
          setSessionExpired(true);
        } else {
          let combinedData = [];
          if (data.propinas_semana_actual) {
            combinedData = combinedData.concat(
              data.propinas_semana_actual.map((item) => ({ ...item }))
            );
          }
          if (data.transacciones_semana_actual) {
            combinedData = combinedData.concat(
              data.transacciones_semana_actual.map((item) => ({ ...item }))
            );
          }

          setWeeklyData(combinedData);
        }
      };
      fetchData();
    }
  }, [token, navigate]);

  useEffect(() => {
    if (weeklyData.length > 0) {
      const groupedData = weeklyData.reduce((acc, data) => {
        const dateToFormat =
          data.hora_aprovacion_pago || data.hora_transferencia;
        const formattedDate = formatDate(dateToFormat);
        if (!acc[formattedDate]) {
          acc[formattedDate] = [];
        }
        acc[formattedDate].push(data);
        return acc;
      }, {});

      const orderedGroupedData = {};
      if (groupedData["Hoy"]) {
        orderedGroupedData["Hoy"] = groupedData["Hoy"];
      }
      if (groupedData["Ayer"]) {
        orderedGroupedData["Ayer"] = groupedData["Ayer"];
      }

      const otherDates = Object.keys(groupedData)
        .filter((date) => date !== "Hoy" && date !== "Ayer")
        .sort((a, b) => new Date(a) - new Date(b));

      otherDates.forEach((date) => {
        orderedGroupedData[date] = groupedData[date];
      });

      const url = window.location.pathname + window.location.search;

      if (url.includes("/dashboard?s")) {
        setGroupedPayments("");
      } else {
        setGroupedPayments(orderedGroupedData);
      }

      let totalPendiente = 0;
      let totalTransferido = 0;
      let totalPropinas = 0;

      weeklyData.forEach((payment) => {
        if (payment.tipo === "Transferencia" && payment.estado === "aprobada") {
          totalTransferido -= Math.abs(payment.monto); // Resta el monto transferido
        } else if (payment.tipo === "Deposito") {
          if (payment.estado === "pendiente") {
            totalPendiente += payment.monto; // Sumar el monto pendiente
          }
        } else if (
          payment.tipo !== "Transferencia" &&
          payment.tipo !== "Deposito"
        ) {
          totalPropinas += payment.monto; // Sumar el monto de propinas
        }
      });

      // Actualizar estados para poder ver los datos
      setTotalPendiente(totalPendiente);
      setTotalTransferido(totalTransferido);
      setTotalPropinas(totalPropinas);

      // Imprimir en MALDITA consola de Firefox
      console.log("Total monto pendiente:", totalPendiente);
      console.log("Total monto transferido:", totalTransferido);
      console.log("Total monto propinas:", totalPropinas);

      const total = totalTransferido + totalPropinas;
      setTotalPayments(total);
    }
  }, [weeklyData]);

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: isVisible ? 1000 : -1000,
          backgroundColor: "#FF5EA3",
          opacity: isVisible ? 1 : 0,
          transition: "opacity .3s, z-index 0s 0.3s",
        }}
      />
      <div
        className="w-full min-h-screen pt-3 pb-10"
        style={{
          background: "linear-gradient(to bottom, #f45b9e 5%, #eee 40%)",
        }}
      >
        <div className="flex items-left justify-left text-white w-11/12 mx-auto mb-2 mt-3 text-sm">
          <PersonCircle className="mr-2" width={20} height={20} />
          <h2>Hola </h2>
        </div>

        <div
          className={`flex rounded-md w-11/12 mx-auto py-5 cursor-pointer transition-all  max-w-[600px] ${
            isAmountVisible
              ? "shadow-lg shadow-pink-700 bg-white"
              : "bg-pink-900 text-white"
          }`}
        >
          <div
            className="w-full"
            onClick={() => setIsAmountVisible(!isAmountVisible)}
          >
            <div className="text-3xl font-semibold font-mono flex items-center justify-center">
              {isAmountVisible ? `$${totalPayments.toFixed(2)}` : "$****"}
              {isAmountVisible ? (
                <Eye className="ml-3" width={30} height={25} />
              ) : (
                <EyeSlash className="ml-3" width={30} height={25} />
              )}
            </div>

            <div className="overflow-x-auto">
              {isAmountVisible ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Tus propinas
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-green-600">
                        ${totalPropinas.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Total pendiente
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${totalPendiente.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Total transferido
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-red-500">
                        ${totalTransferido.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : null}
            </div>

            <div className="flex items-center justify-center text-sm mt-2">
              <Coin width={20} height={20} />
              &nbsp; Total propinas semanal
            </div>
          </div>
        </div>

        {Object.entries(groupedPayments).length !== 0 ? (
          <div className="text-center mt-3">
            <h2 className="text-white font-semibold">
              Estado de tu cuenta Tippit
            </h2>
          </div>
        ) : (
          <div className="text-center mt-8"></div>
        )}

        <div
          className="w-11/12 pt-5 mt-2 px-5 pb-5 min-h-full mx-auto bg-white shadow-lg shadow-gray-250 rounded-lg max-w-[600px]"
          id="WeeklyReporte"
        >
          {Object.entries(groupedPayments).length === 0 ? (
            <div className="text-center">
              {sessionStorage.getItem("vinculado_mp") === "true" ? (
                <div>
                  <p className="text-xl">
                    <b>Esta semana no tuviste ninguna propina!</b>
                  </p>
                  <div className="mt-3">
                    No desesperes! Aca te dejamos algunos consejos: <Consejos />
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="font-bold text-xl mb-1 mt-4">
                    Un pasito mas...
                  </h2>
                  <p className="font-regular text-sm">
                    Para poder recibir propinas en tu cuenta, necesitamos que
                    conectes Tippit con tu MercadoPago.
                  </p>

                  <img
                    src="Tippit-logo-Texto-1024x242.png"
                    className="img-liquid max-w-[200px] rounded-lg p-4 mx-auto border-pink-400 border-2 shadow-md mt-6"
                    alt=""
                  />

                  <ArrowDownUp
                    className="mx-auto my-3"
                    width={40}
                    height={40}
                  />

                  <img
                    src="/mercado.png"
                    alt=""
                    className="img-fluid w-[200px] mx-auto border-2 border-indigo-300 p-2 rounded-lg shadow-md"
                  />

                  <MercadoPago />
                </div>
              )}
            </div>
          ) : (
            Object.entries(groupedPayments).map(([date, payments], index) => (
              <div key={index}>
                <h3 className="bg-gray-100 rounded-md p-2 text-sm mt-2">
                  {date}
                </h3>
                {/* <h3 className="bg-gray-100 rounded-md p-2 text-sm mt-2">{date}x</h3> */}
                {payments
                  .slice()
                  .reverse()
                  .map((payment, paymentIndex) => (
                    <div
                      key={paymentIndex}
                      className={`py-5 flex flex-row items-center text-sm relative ${payment.tipo === "Transferencia" || payment.tipo === "Deposito" ? 'hidden' : ''}`}
                    >
                      {/* LINK DE PAGOS */}
                      <div>
                        {payment.tipo === "Transferencia" &&
                          payment.estado === "pendiente" && (
                            <button
                              className="text-blue-500 underline bg-transparent cursor-pointer w-full absolute h-full border-none top-0 left-0"
                              onClick={() => openModal(payment)}
                            ></button>
                          )}
                        <div id="modal-root"></div>
                        





                        <Modal isOpen={isOpen} onClose={closeModal}>
  {modalData && (
    <>
      <div className={`transition-opacity duration-500 ${numeroOperacionAyuda ? 'opacity-100 scale-100' : 'opacity-0 scale-95 hidden'}`}>
        <img src="numero_operacion.jpg" alt="Número de operación" className="rounded-lg w-full" />
        <button
          className="transition-all duration-300 ease-in-out mt-5 w-full px-2 py-4 text-white font-semibold bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105 p-3"
          onClick={() => setNumeroOperacionAyuda(false)}
        >
          Okay, ya entendí
        </button>
      </div>

      <div className={`transition-opacity duration-500 ${numeroOperacionAyuda ? 'opacity-0 scale-95 hidden' : 'opacity-100 scale-100'}`}>
        <div className="p-1 max-w-[80vw]">
          <h2 className="mb-4 text-xl">Transferencia pendiente</h2>
          <p className="text-lg">
            Necesitas transferir a: <b>{modalData.transferio_a}</b>
          </p>
          <p className="text-lg">
            Monto: <b>${Math.abs(modalData.monto).toFixed(2)}</b>
          </p>
          <p className="text-lg">
            CUIT: <b>{modalData.cuit_acreedor}</b>
          </p>

          {!apiMessage.includes("éxito") && (
            <>
              <p>
                <label htmlFor="operacion" className="block mt-3 mb-1">
                  Número de Operación
                </label>
                <input
                  type="text"
                  name="operacion"
                  value={numeroOperacion}
                  onChange={(e) => {
                    setNumeroOperacion(e.target.value);
                    setApiMessage(""); 
                  }}
                  className="border w-full rounded-md border-l-2 border-gray-400 p-2 text-lg"
                  disabled={apiMessage.includes("error")}
                />
              </p>

              <div className="text-center w-full">
                <button
                  className="transition-all duration-300 ease-in-out mt-3 w-full px-2 py-4 text-white font-semibold bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105 p-3"
                  onClick={verificarTransferencia}
                >
                  Ya transferí ${Math.abs(modalData.monto).toFixed(2)}
                </button>
              </div>
            </>
          )}

          <p className={`text-center mt-1 ${apiMessage.includes("Error") ? 'text-red-600' : 'text-green-600 text-lg'}`}>
          <img 
            src="Tippit-logo-cuadrado-trasparente-300x300.png" 
            alt=""
            className={`max-w-[120px] mx-auto hue-rotate-180 ${apiMessage.includes("éxito") ? 'block' : 'hidden'}`}
            />

            {apiMessage}
          </p>
        </div>
      </div>
    </>
  )}
</Modal>













                        

                      </div>

                      <div className="w-1/5 pr-2">
                        {payment.mesa ? (
                          // si es una mesa, es una propina
                          <div className="w-full relative md:transform md:scale-[1.25]">
                            <div className="absolute w-full h-full flex items-end justify-center text-lg text-white font-mono ml-[-3px]">
                              {payment.mesa}
                            </div>
                            <CupHotFill
                              size={42}
                              color="#f05e9e"
                              className="mx-auto"
                            />
                          </div>
                        ) : (
                          <div className="w-full relative md:transform md:scale-[1.25] ">
                            {payment.tipo === "Transferencia" ? (
                              <>
                                {payment.estado === "aprobada" ? (
                                  <CheckCircle
                                    size={42}
                                    color="#888"
                                    className="mx-auto"
                                  />
                                ) : (
                                  <ExclamationCircle
                                    size={42}
                                    color="red"
                                    className="mx-auto blinking"
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {payment.estado === "pendiente" ? (
                                  <ClockHistory
                                    size={42}
                                    color="#ccc"
                                    className="mx-auto"
                                  />
                                ) : (
                                  <Coin
                                    size={42}
                                    color="green"
                                    className="mx-auto"
                                  />
                                )}
                              </>
                            )}
                            <div className="absolute w-full h-full flex items-end justify-center text-lg text-white font-mono ml-[-3px]"></div>
                          </div>
                        )}
                      </div>
                      <div className="w-80 text-gray-600">
                        <>
                          {payment.tipo === "Transferencia" ? (
                            <>
                              {payment.estado === "aprobada" ? (
                                <div>
                                  Transferiste a {payment.transferio_a}
                                  <br />
                                  <span className="text-green-600">
                                    Transferido ✔
                                  </span>
                                </div>
                              ) : payment.estado === "pendiente" ? (
                                <div>
                                  Transferencia pendiente a{" "}
                                  {payment.transferio_a}
                                </div>
                              ) : null}
                              <div className="text-sm text-gray-400 capitalize">
                                {payment.Restaurante}
                              </div>
                            </>
                          ) : payment.tipo === "Deposito" ? (
                            <>
                              Deposito {payment.estado}
                              <br />
                              {payment.Restaurante}
                            </>
                          ) : payment.hora_aprovacion_pago ? (
                            <>
                              <div>
                                {payment.estado === "approved"
                                  ? "Propina "
                                  : payment.estado}
                              </div>
                              <div>
                                {payment.estado_detallado === "accredited" ? (
                                  <span className="text-green-600">
                                    Depositado ✔
                                  </span>
                                ) : (
                                  "Acreditación Pendiente"
                                )}
                              </div>

                              <div className="text-sm text-gray-400 capitalize">
                                {payment.Restaurante}
                              </div>
                              <div className="flex mt-1">
                                {Array.from({ length: 5 }, (_, index) => (
                                  <svg
                                    key={index}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill={
                                      index < payment.calificacion
                                        ? "currentColor"
                                        : "lightgray"
                                    }
                                    className="bi bi-star-fill"
                                    viewBox="0 0 16 16"
                                    style={{ marginRight: "2px" }}
                                  >
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                  </svg>
                                ))}
                              </div>
                            </>
                          ) : null}
                        </>
                      </div>
                      <div className="w-1/2 text-right pr-2">
                        <span
                          className={
                            payment.tipo === "Deposito" &&
                            payment.estado === "pendiente"
                              ? "text-[#bbb]"
                              : payment.tipo === "Deposito" &&
                                payment.estado === "approved"
                              ? "text-green-700"
                              : payment.tipo === "Transferencia"
                              ? "text-red-500"
                              : "text-green-700"
                          }
                        >
                          ${payment.monto ? payment.monto.toFixed(2) : "0.00"}
                        </span>

                        <br />
                        <span className="text-gray-400 text-xs">
                          {payment.tipo === "Transferencia" ||
                          payment.tipo === "Deposito" ? (
                            <>
                              {getAbbreviatedDay(payment.hora_transferencia)}{" "}
                              {new Date(payment.hora_transferencia)
                                .toLocaleDateString("es-AR", {
                                  day: "2-digit",
                                  month: "short",
                                })
                                .toUpperCase()}{" "}
                              {new Date(
                                payment.hora_transferencia
                              ).toLocaleTimeString("es-AR", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })}
                            </>
                          ) : (
                            <>
                              {getAbbreviatedDay(payment.hora_aprovacion_pago)}{" "}
                              {new Date(payment.hora_aprovacion_pago)
                                .toLocaleDateString("es-AR", {
                                  day: "2-digit",
                                  month: "short",
                                })
                                .toUpperCase()}{" "}
                              {new Date(
                                payment.hora_aprovacion_pago
                              ).toLocaleTimeString("es-AR", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            ))
          )}

          {sessionExpired && <SessionOut />}
        </div>
      </div>
    </div>
  );
}

export default MozoDashboard;
